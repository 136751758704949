import React from "react"

const FacebookIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path d="M10.5 23v-8.8H6.8v-3.8h3.8V8c0-3.4 2.1-5 5.1-5 1.5 0 2.6.1 3 .1v3.5h-2.1c-1.6 0-2 .8-2 1.9v2h4.8L18 14.2h-3.4V23h-4.1z" />
    </svg>
  )
}

const InstagramIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path d="M19.4 7.9c0 .7-.5 1.2-1.2 1.2S17 8.6 17 7.9s.5-1.2 1.2-1.2c.6 0 1.2.5 1.2 1.2zM18 13c0 2.8-2.2 5-5 5s-5-2.2-5-5 2.2-5 5-5 5 2.2 5 5zm-2.5 0c0-1.4-1.1-2.5-2.5-2.5s-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5zM23 8v10c0 2.6-2.4 5-5 5H8c-2.6 0-5-2.4-5-5V8c0-2.6 2.4-5 5-5h10c2.6 0 5 2.4 5 5zm-2.5 0c0-1.2-1.3-2.5-2.5-2.5H8C6.8 5.5 5.5 6.8 5.5 8v10c0 1.2 1.3 2.5 2.5 2.5h10c1.2 0 2.5-1.3 2.5-2.5V8z" />
    </svg>
  )
}

const TwitterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path d="M23 6.8c-.8.4-1.5.5-2.4.6.9-.5 1.5-1.2 1.8-2.2-.8.5-1.6.8-2.6 1-.8-.8-1.9-1.2-3-1.2-2.1 0-4 1.9-4 4.1 0 .4 0 .6.1.9-3.4-.2-6.5-1.9-8.5-4.4-.4.7-.5 1.3-.5 2.2 0 1.4.8 2.6 1.9 3.4-.6 0-1.2-.2-1.9-.5 0 2 1.4 3.6 3.3 4-.4.1-.8.1-1.1.1-.2 0-.5 0-.8-.1.5 1.6 2 2.9 3.9 2.9-1.4 1-3.2 1.6-5.2 1.6H3c1.9 1.1 4 1.9 6.2 1.9 7.5 0 11.6-6.3 11.6-11.6V9c1-.6 1.7-1.4 2.2-2.2z" />
    </svg>
  )
}

const YoutubeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path d="M22.8 9c-.2-1.6-1-2.8-2.8-3-2.8-.5-7-.5-7-.5s-4.2 0-7 .5c-1.8.2-2.6 1.4-2.8 3-.2 1.6-.2 4-.2 4s0 2.4.2 4 1 2.8 2.8 3c2.8.5 7 .5 7 .5s4.2 0 7-.5c1.8-.4 2.5-1.4 2.8-3s.2-4 .2-4 0-2.4-.2-4zm-12.3 7.8V9.2l6.2 3.8-6.2 3.8z" />
    </svg>
  )
}

const LinkedInIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path d="M3.5 9.6h4.2v13.2H3.5V9.6zM5.6 3C4.2 3 3.2 4 3.2 5.4c0 1.2 1 2.4 2.4 2.4 1.2 0 2.3-1.2 2.4-2.4C8 4 7 3 5.6 3zM18 9.3c-1.9 0-3.3 1-3.8 2.1V9.6h-4V23h4.2v-6.6c0-1.7.3-3.5 2.4-3.5s2.1 1.9 2.1 3.5v6.4h4v-7.3c-.1-3.5-.8-6.2-4.9-6.2z" />
    </svg>
  )
}

export { FacebookIcon, InstagramIcon, TwitterIcon, YoutubeIcon, LinkedInIcon }

import React from "react"
import PropTypes from "prop-types"

import Link from "lib-vinomofo/components/link"
import imageURL from "lib-vinomofo/utils/image-url"
import StackedContainer from "lib-vinomofo/components/stacked-container"
import classNames from "lib-vinomofo/utils/class-names"
import LazyImage from "lib-vinomofo/components/lazy-image"

import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"

import styles from "./article-card.module.scss"

const breakpoints = [
  Breakpoint("medium", [270, 529]),
  Breakpoint("large", [530, null]),
]

const ArticleCard = ({ children }) => {
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)

  const classes = classNames([
    styles.articleCard,
    breakpoint && styles[breakpoint],
  ])

  return (
    <div className={classes} ref={breakpointRef}>
      <StackedContainer space={1}>{children}</StackedContainer>
    </div>
  )
}

const ArticleCardImage = ({ src }) => {
  if (!src) return null

  const smallOne = imageURL(src, {
    width: 50,
    height: 50,
    quality: 100,
    blur: 40,
    fit: "crop",
  })

  const bigOne = imageURL(src, {
    width: 570,
    height: 570,
    fit: "crop",
  })

  const blurStyles = { backgroundImage: `url("${smallOne}")` }

  return (
    <div className={styles.articleCardImageWrapper}>
      <div className={styles.articleCardImage} style={blurStyles}>
        <LazyImage src={bigOne} />
      </div>
    </div>
  )
}

ArticleCardImage.propTypes = {
  src: PropTypes.string.isRequired,
}

const ArticleCardTitle = ({ children }) => {
  return <h3 className={styles.articleCardTitle}>{children}</h3>
}

const ArticleCardText = ({ children, maxLines }) => {
  const classes = classNames([
    styles.articleCardText,
    maxLines && styles[`maxLines${maxLines}`],
  ])

  return (
    <div className={classes}>
      <p>{children}</p>
      <div className={styles.textMask} />
    </div>
  )
}

ArticleCardText.propTypes = {
  maxLines: PropTypes.oneOf([null, 2, 3, 4, 5]),
}

const ArticleCardCTA = ({ route, link, children }) => {
  return (
    <Link route={route} link={link} className={styles.articleCardCTA}>
      {children}
    </Link>
  )
}

ArticleCardCTA.propTypes = {
  route: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  link: PropTypes.string,
}

export {
  ArticleCard,
  ArticleCardImage,
  ArticleCardTitle,
  ArticleCardText,
  ArticleCardCTA,
}

import React, { Fragment } from "react"

import Navigation from "./navigation"
import Legals from "./legals"

const SiteFooter = () => {
  return (
    <Fragment>
      <Navigation />
      <Legals />
    </Fragment>
  )
}

export default SiteFooter

import React from "react"

import GridContainer from "lib-vinomofo/components/grid-container"
import Link from "lib-vinomofo/components/link"

import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"

import {
  ArticleCard,
  ArticleCardImage,
  ArticleCardTitle,
  ArticleCardText,
  ArticleCardCTA,
} from "../article-card"

const breakpoints = [
  Breakpoint(2, [null, 649]),
  Breakpoint(3, [650, 899]),
  Breakpoint(4, [900, null]),
]

const ArticleGrid = ({ articles }) => {
  const [numCols, breakpointRef] = useWidthBreakpoints(breakpoints)
  articles = articles.filter(article => article && article.title)

  return (
    <div ref={breakpointRef}>
      <GridContainer numCols={numCols} space={2}>
        {articles.map(({ title, thumbnail, excerpt, slug }) => (
          <ArticleCard key={slug}>
            <Link route={{ slug }}>
              <ArticleCardImage src={thumbnail} />
            </Link>
            <ArticleCardTitle>
              <Link route={{ slug }}>{title}</Link>
            </ArticleCardTitle>
            <ArticleCardText maxLines={4}>{excerpt}</ArticleCardText>
            <ArticleCardCTA route={{ slug }}>Keep reading</ArticleCardCTA>
          </ArticleCard>
        ))}
      </GridContainer>
    </div>
  )
}

export default ArticleGrid

import React from "react"
import PropTypes from "prop-types"
import { fill, chunk } from "lodash"

import StackedContainer from "../stacked-container"

const GridContainer = ({ numCols, space, children }) => {
  const padding = fill(new Array(numCols), <div />)
  const rows = chunk(React.Children.toArray(children), numCols).map(row => {
    return [...row, ...padding].slice(0, numCols)
  })

  return (
    <StackedContainer space={space}>
      {rows.map((row, ix) => {
        return (
          <StackedContainer key={ix} horizontal space={space} distribute="fill">
            {row}
          </StackedContainer>
        )
      })}
    </StackedContainer>
  )
}

GridContainer.defaultProps = {
  space: 0,
  numCols: 4,
}

GridContainer.propTypes = {
  space: PropTypes.number,
  numCols: PropTypes.number,
}

export default GridContainer

export const facebookUrl = handle => {
  return handle.replace(/^@/, "https://www.facebook.com/")
}

export const twitterUrl = handle => {
  return handle.replace(/^@/, "https://twitter.com/")
}

export const instagramUrl = handle => {
  return handle.replace(/^@/, "https://instagram.com/")
}

export const linkedInUrl = handle => {
  return handle.replace(/^@/, "https://linkedin.com/company/")
}

export const youtubeUrl = handle => {
  return handle.replace(/^@/, "https://www.youtube.com/user/")
}

import React from "react"
import PropTypes from "prop-types"

const UserIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path
        d="M17.3 13.5C18.9 12.2 20 10.2 20 8c0-3.9-3.1-7-7-7S6 4.1 6 8c0 2.2 1.1 4.2 2.7 5.5C4.2 14.9 1 19.1 1 24v1h2v-1c0-5 4-9 9-9h2c5 0 9 4 9 9v1h2v-1c0-4.9-3.2-9.1-7.7-10.5zM8 8c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z"
        fill={color}
      />
    </svg>
  )
}

UserIcon.propTypes = {
  color: PropTypes.string,
}

export default UserIcon

import React from "react"
import PropTypes from "prop-types"

const CartIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path
        d="M7.9 19c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm12-4c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1.8-5l2.4-12H6.4l-.6-5H1.9v2H4l2 15h15.7zm0-10l-1.6 8H7.8L6.7 8h15z"
        fill={color}
      />
    </svg>
  )
}

CartIcon.propTypes = {
  color: PropTypes.string,
}

export default CartIcon

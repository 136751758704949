import React from "react"
import PropTypes from "prop-types"

const MicrophoneIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path
        d="M21.5 12.4c0 4.1-3.3 7.6-7.3 8.2v4h-2.4v-4c-4-.6-7.3-4-7.3-8.2h2.1c0 3.6 3.1 6.2 6.4 6.2 3.4 0 6.4-2.6 6.4-6.2h2.1zM13 16c2 0 3.6-1.6 3.6-3.6V5.1c0-2-1.6-3.6-3.6-3.6S9.4 3.1 9.4 5.1v7.3c0 2 1.6 3.6 3.6 3.6z"
        fill={color}
      />
    </svg>
  )
}

MicrophoneIcon.propTypes = {
  color: PropTypes.string,
}

export default MicrophoneIcon

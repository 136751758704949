import React from "react"
import PropTypes from "prop-types"
import GatsbyLink from "gatsby-link"

const Link = ({ children, route, link, ...props }) => {
  if (route) {
    const slug = route.slug.replace(/^\/?(.*?)\/?$/, "/$1/")

    return (
      <GatsbyLink to={slug} {...props}>
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={link} {...props}>
      {children}
    </a>
  )
}

Link.propTypes = {
  route: PropTypes.shape({ slug: PropTypes.string.isRequired }),
  link: PropTypes.string,
}

export default Link

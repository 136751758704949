import React from "react"
import PropTypes from "prop-types"

const PhoneIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path
        d="M18.5 14.1l-3 3-6.6-6.6 3-3-6.4-6.4-4 4v.4a19 19 0 0 0 19 19h.4l4-4-6.4-6.4zm1.6 8.4C11 22.3 3.7 15 3.5 5.9l2-2 3.6 3.6-3 3 9.4 9.4 3-3 3.6 3.6-2 2z"
        fill={color}
      />
    </svg>
  )
}

PhoneIcon.propTypes = {
  color: PropTypes.string,
}

export default PhoneIcon

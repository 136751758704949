import PropTypes from "prop-types"
import React from "react"

const VinomofoLogo = ({ alternative }) => {
  const vinoColour = alternative ? "#000" : "#fff"
  const mofoColour = "#77B204"

  return (
    <svg viewBox="0 0 300 75" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path fill={vinoColour} d="M25.1142.7923h-1.1967l.8129 2.6224-.196 1.3494-.6252 4.044h.033l-4.6795 32.0055-.4333 3.0702h-.652l-.4312-3.0702-4.6836-32.0055h.0268l-.4931-3.194-.324-2.218.8089-2.6038H0l1.8177 8.0158h.031l10.8012 64.793h11.707l10.7992-64.793h.0268L36.9986.7923h-.5055zM85.5846.7923h-1.4443l1.4443 4.4257v36.881h-.65l-.9738-3.3652L72.5778.7923h-11.899l1.4918 4.5619v5.3026h.0062v62.9443h10.727V32.2923h.6498l.978 3.3693 11.379 37.9395h10.403V5.0117L97.692.7923h-1.3783zM119.8287 0c-15.7118 0-15.6066 15.749-15.6066 15.749v42.8934s-.1052 15.7613 15.6066 15.7613c15.7119 0 15.615-15.7613 15.615-15.7613V15.749S135.5426 0 119.8286 0zm0 63.9924c-4.1265 0-3.9016-5.35-3.9016-5.35V15.749s-.2063-5.348 3.9016-5.348c4.108 0 3.8996 5.348 3.8996 5.348v42.8934s.2187 5.35-3.8996 5.35zM54.9326.7923H40.3122l1.4897 4.5619v5.3026h.002v53.0798h-.002v5.3005l-1.4897 4.564h14.6204l-1.4257-4.3659V5.1623z"/>
        <path fill={mofoColour} d="M260.0901 11.7895l3.9924 1.1493L262.0853.7923h-29.3645l1.4443 4.4175v63.9284l-1.4567 4.4629h14.6224l-1.4608-4.4752V41.8906h16.1472V30.9058H245.87V11.7895zM284.3521 0c-15.7118 0-15.6025 15.749-15.6025 15.749v42.8934s-.1093 15.751 15.6025 15.751c15.7119 0 15.6046-15.751 15.6046-15.751V15.749S300.066 0 284.352 0zm0 63.9924c-4.1265 0-3.9016-5.35-3.9016-5.35V15.749s-.2063-5.348 3.9016-5.348c4.108 0 3.9017 5.348 3.9017 5.348v42.8934s.2187 5.35-3.9017 5.35zM210.6417 0c-15.714 0-15.6025 15.749-15.6025 15.749v42.8934s-.1114 15.7613 15.6025 15.7613c15.7139 0 15.6107-15.751 15.6107-15.751V15.749S226.3618 0 210.6417 0zm0 63.9924c-4.1266 0-3.8975-5.35-3.8975-5.35V15.749s-.2188-5.348 3.8975-5.348c4.1162 0 3.9037 5.348 3.9037 5.348v42.8934s.2166 5.35-3.9037 5.35zM187.1286 10.6568h.0041V5.3542l1.4856-4.5619H172.8219l-6.9347 33.2847-.5426 3.8625h-.2167l-.5447-3.8625L157.6486.7923H141.846l1.4896 4.5619v5.3026h.0103v58.4814l-1.4566 4.4629h13.7764l-1.4814-4.5413V24.7634h.6479l.4333 3.0702 7.4773 45.7675h4.9848l7.4774-45.7675.4353-3.0702h.65v44.4512l-1.432 4.3865H188.6183l-1.4897-4.564v-5.3005z"/>
      </g>
    </svg>
  )
}

VinomofoLogo.propTypes = {
  isAlternative: PropTypes.bool,
}

VinomofoLogo.defaultProps = {
  isAlternative: false,
}

export default VinomofoLogo

import React from "react"
import PropTypes from "prop-types"

const SearchIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <path
        d="M24.4 23L18 16.6A8.96 8.96 0 0 0 11 2c-5 0-9 4-9 9s4 9 9 9c2.1 0 4.1-.7 5.6-2l6.4 6.4 1.4-1.4zM4 11c0-3.9 3.1-7 7-7s7 3.1 7 7-3.1 7-7 7-7-3.1-7-7z"
        fill={color}
      />
    </svg>
  )
}

SearchIcon.propTypes = {
  color: PropTypes.string,
}

export default SearchIcon

import React, { Fragment } from "react"

import { useMarketContext } from "../../../contexts/market-context"

const AU = () => (
  <Fragment>
    <p>Under the Liquor Control Reform Act 1998 it is an offence:</p>

    <ul>
      <li>
        to supply alcohol to a person under the age of 18 years (penalty exceeds
        $23,000).
      </li>
      <li>
        for a person under the age of 18 years to purchase or receive liquor
        (penalty exceeds $900)
      </li>
    </ul>

    <p>Liquor Licence No. 36300937</p>
  </Fragment>
)

const SG = () => (
  <Fragment>
    <p>
      Under the Liquor Control (Supply and Consumption) Act 2015 it is an
      offence:
    </p>

    <ul>
      <li>to supply any liquor to a person under the age of 18 years;</li>
      <li>
        for a person under the age of 18 years to buy or attempt to buy liquor.
      </li>
    </ul>

    <p>
      No person under the age of 18 will be permitted to purchase wine from
      Vinomofo Asia Holdings Pte. Ltd.
    </p>
    <p>Name of Licensee: Vinomofo Asia Holdings Pte Ltd</p>
    <p>Class 4 licence No. L/LL/047229/2023/P</p>
    <p>Class 3A Licence No. L/LL/047228/2023/P</p>
  </Fragment>
)

const NZ = () => (
  <Fragment>
    <p>Under the Liquor Control Reform Act 1998 it is an offence:</p>

    <ul>
      <li>
        to supply alcohol to a person under the age of 18 years (max penalty
        $2,000).
      </li>
      <li>
        for a person under the age of 18 years to purchase or receive liquor
        (max penalty $2,000)
      </li>
    </ul>

    <p>Liquor Licence No. 36128660</p>
  </Fragment>
)

const texts = { AU, NZ, SG }

const FooterText = () => {
  const market = useMarketContext()
  const MarketText = texts[market.code]

  return <MarketText />
}

export default FooterText

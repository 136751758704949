import "intersection-observer"

const observeIntersection = (element, callback, options) => {
  const observer = new IntersectionObserver(entries => {
    for (const entry of entries) {
      callback(entry.target, entry.isIntersecting)
    }
  }, options)

  observer.observe(element)
  return () => observer.unobserve(element)
}

const handleOnscreen = (element, callback, threshold = 0) =>
  observeIntersection(element, callback, { threshold })

export { handleOnscreen, observeIntersection }

import ImgixClient from "imgix-core-js"

import devicePixelRatio from "./device-pixel-ratio"

const keyMap = {
  auto: "auto",
  height: "h",
  width: "w",
  crop: "crop",
  fit: "fit",
  blur: "blur",
  format: "fm",
  quality: "q",
  pixelate: "px",
  monochrome: "mono",
  sepia: "sepia",
  pixelDensity: "dpr",
  focalpointX: "fp-x",
  focalpointY: "fp-y",
  focalpointDebug: "fp-debug",
}

const imageURL = (src, opts) => {
  const client = new ImgixClient({
    domain: process.env.GATSBY_IMGIX_URL,
    secureURLToken: process.env.GATSBY_IMGIX_TOKEN,
  })

  const params = {
    auto: "compress,format",
    dpr: devicePixelRatio(),
    q: 90,
  }

  for (const key in opts) {
    if (key in keyMap) params[keyMap[key]] = opts[key]
  }

  return client.buildURL(src, params)
}

export default imageURL

import React, { Fragment } from "react"
import Helmet from "react-helmet"

import PageSection from "lib-vinomofo/components/page-section"
import SiteHeader from "lib-vinomofo/components/site-header"
import SiteFooter from "lib-vinomofo/components/site-footer"

import { useMarketContext } from "lib-vinomofo/contexts/market-context"

const PageLayout = ({ children }) => {
  const market = useMarketContext()

  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: market.lang }}></Helmet>

      <header>
        <PageSection backgroundColor="#131314">
          <SiteHeader />
        </PageSection>
      </header>

      <main>{children}</main>

      <footer>
        <SiteFooter />
      </footer>
    </Fragment>
  )
}

export default PageLayout

import React, { useMemo } from "react"

import { useWidthBreakpoints, Breakpoint } from "../../hooks/use-breakpoints"
import SizingWrapper from "../sizing-wrapper"
import StackedContainer from "../stacked-container"
import Link from "../link"

import classNames from "../../utils/class-names"
import sample from "../../utils/sample"

import { useMarketContext } from "../../contexts/market-context"
import { useSearchModal } from "../../contexts/search-modal-context"
import { useSession } from "../../contexts/session-context"
import { useCheckout } from "../../contexts/checkout-context"
import * as Session from "../../types/session"
import * as Checkout from "../../types/checkout"

import VinomofoLogo from "../vinomofo-logo"
import { UserIcon, CartIcon, PhoneIcon, SearchIcon } from "../icons"

import styles from "./site-header.module.scss"

const GREETINGS = [
  "Aloha",
  "Hello",
  "Hi",
  "もしもし",
  "¡Buenos Días",
  "Yo",
  "Sup",
  "Ahoy-hoy",
  "Guten Tag",
  "Good Day",
  "¡Hola",
  "Bonjour",
  "Namaste",
  "Salam",
  "Salut",
  "Ellohay",
  "Oh Hello",
]

const MemberGreeting = ({ name }) => {
  const greeting = useMemo(() => sample(GREETINGS), [])

  return (
    <div className={styles.greeting}>
      <Link link="/account">
        {greeting} {name}!
      </Link>
    </div>
  )
}

const Authlinks = () => {
  return (
    <div className={styles.greeting}>
      <Link link="/login">Login</Link> / <Link link="/join">Join</Link>
    </div>
  )
}

const Greeting = ({ session }) => {
  if (Session.isInterstitial(session)) {
    return null
  } else if (Session.isAuthenticated(session)) {
    return <MemberGreeting name={session.currentUser.profile.first_name} />
  } else {
    return <Authlinks />
  }
}

const DefaultHeader = ({ checkout }) => {
  const market = useMarketContext()
  const searchModal = useSearchModal()
  const showSearchModal = () => {
    searchModal.toggleVisibility(true)
  }

  return (
    <StackedContainer distribute="evenly">
      <StackedContainer horizontal distribute="evenly">
        <nav className={styles.leftNav}>
          <SiteLink to="/account">
            <div className={styles.navIcon}>
              <UserIcon />
            </div>
          </SiteLink>
          <SiteLink to={`tel:${market.telephone}`}>
            <div className={styles.navIcon}>
              <PhoneIcon />
            </div>
          </SiteLink>
        </nav>

        <a className={styles.logo} href="/">
          <VinomofoLogo />
        </a>

        <nav className={styles.rightNav}>
          <SiteLink onClick={showSearchModal}>
            <div className={styles.navIcon}>
              <SearchIcon />
            </div>
          </SiteLink>
          <CartLink checkout={checkout} />
        </nav>
      </StackedContainer>

      <nav className={styles.nav}>
        <StackedContainer horizontal>
          <SiteLink to="/wines">Shop</SiteLink>
          {market.code === "AU" && (
            <SiteLink to="/wine-clubs">Wine Clubs</SiteLink>
          )}
          <SiteLink to="/articles/">Articles</SiteLink>
        </StackedContainer>
      </nav>
    </StackedContainer>
  )
}

const ExpandedHeader = ({ session, checkout }) => {
  const market = useMarketContext()

  return (
    <StackedContainer distribute="middle">
      <StackedContainer horizontal distribute="fill">
        <nav className={styles.leftNav}>
          <SiteLink to="/wines">Shop</SiteLink>
          {market.code === "AU" && (
            <SiteLink to="/wine-clubs">Wine Clubs</SiteLink>
          )}
          <SiteLink to="/articles/">Articles</SiteLink>
        </nav>

        <a className={styles.logo} href="/">
          <VinomofoLogo />
        </a>

        <ExpandedRightNav session={session} checkout={checkout} />
      </StackedContainer>
    </StackedContainer>
  )
}

const expandedRightNavBreakpoints = [Breakpoint("large", [340, null])]

const ExpandedRightNav = ({ session, checkout }) => {
  const [current, ref] = useWidthBreakpoints(expandedRightNavBreakpoints)
  const searchModal = useSearchModal()
  const showSearchModal = () => {
    searchModal.toggleVisibility(true)
  }

  return (
    <nav ref={ref} className={styles.rightNav}>
      <StackedContainer space={1} horizontal>
        {"large" === current && <Greeting session={session} />}
        <SiteLink to="/account">
          <div className={styles.navIcon}>
            <UserIcon />
          </div>
        </SiteLink>
      </StackedContainer>
      <SiteLink onClick={showSearchModal}>
        <div className={styles.navIcon}>
          <SearchIcon />
        </div>
      </SiteLink>
      <CartLink checkout={checkout} large />
    </nav>
  )
}

const CartLink = ({ checkout, large = false }) => {
  const Indicator = large ? CartItemCount : CartIndicator

  return (
    <div className={styles.cartLink}>
      <SiteLink to="/cart">
        <StackedContainer horizontal>
          <div className={styles.navIcon}>
            <CartIcon />
          </div>
          <Indicator checkout={checkout} />
        </StackedContainer>
      </SiteLink>
    </div>
  )
}

const CartIndicator = ({ checkout }) => {
  if (Checkout.isInterstitial(checkout) || 0 === checkout.line_items.length)
    return null

  return (
    <div className={styles.cartIndicator}>
      <span className={styles.cartLinkCount}>{checkout.line_items.length}</span>
    </div>
  )
}

const CartItemCount = ({ checkout }) => {
  const classes = classNames([
    styles.cartItemCount,
    !Checkout.isInterstitial(checkout) &&
      checkout.line_items.length > 0 &&
      styles.highlighted,
  ])

  return (
    <div className={classes}>
      {!Checkout.isInterstitial(checkout) && (
        <span>{checkout.line_items.length}</span>
      )}
    </div>
  )
}

const SiteLink = ({ to, onClick, children }) => {
  return (
    <Link link={to} onClick={onClick} className={styles.link}>
      {children}
    </Link>
  )
}

const breakpoints = [Breakpoint("expanded", [800, null])]

const SiteHeader = () => {
  const session = useSession()
  const checkout = useCheckout()
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)
  const classes = classNames([styles.container, styles[breakpoint]])

  const Header = breakpoint ? ExpandedHeader : DefaultHeader

  return (
    <div className={classes} ref={breakpointRef}>
      <SizingWrapper height="100%">
        <Header session={session} checkout={checkout} />
      </SizingWrapper>
    </div>
  )
}

export default SiteHeader

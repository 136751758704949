import React, { useRef, useEffect, useState } from "react"
import propTypes from "prop-types"

import { handleOnscreen } from "../../utils/handle-onscreen"

import classNames from "../../utils/class-names"

import styles from "./lazy-image.module.scss"

const LazyImage = ({ src, className, alt, ...props }) => {
  const imageRef = useRef()
  const [onscreen, setOnscreen] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const classes = classNames([
    className,
    styles.lazyImage,
    loaded && styles.lazyImageLoaded,
  ])

  useEffect(() => {
    return handleOnscreen(imageRef.current, (_el, onscreen) => {
      setOnscreen(onscreen)
    })
  }, [src, imageRef])

  return (
    <img
      ref={imageRef}
      onLoad={e => setLoaded(true)}
      className={classes}
      src={(onscreen || loaded || null) && src}
      alt={alt}
      {...props}
    />
  )
}

LazyImage.propTypes = {
  src: propTypes.string.isRequired,
}

export default LazyImage
